import React from "react"
import { WorldTour } from "../../components/Sunsets2023/WorldTour"
import { Promotions } from "../../components/Sunsets2023/Promotions"
import Footer from "../../components/Sunsets2023/WinterFooter"
import SEO from "../../components/seo"
import { graphql } from "gatsby"

const WorldTourPage = ({data}) => {
  return (
    <div>
      <SEO title="SUNSET FESTIVAL 2023" />
      <WorldTour videos={data.allSunset2023Videos.nodes}/>
      <Promotions />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query SingleSummer2023VideosQuery {
  allSunset2023Videos {
    nodes {
      id
      index
      link
    }
  }
}
`

export default WorldTourPage
