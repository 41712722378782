import React, { useState, useEffect } from "react"
import { graphql, navigate } from "gatsby"
import logo from "../../../images/sunsets2023/worldtour_logo.png"
import styles from "./worldtour.module.scss"
import ReactPlayer from "react-player"
import arrowleft from "../../../images/winter2022/arrowleft.svg"
import { IsMobileDevice } from "../../../utils"

const WorldTour = ({videos}) => {
  const [play, setPlay] = useState(true)

  const [isMobile, setIsMobile] = useState(false)
  useEffect(() => {
    setIsMobile(IsMobileDevice())
  })

  return (
    <>
      <div className={styles.container}>
        <div className={styles.top}>
          <div className={styles.content}>
            <span onClick={() => navigate("/")}>
              {" "}
              <img src={arrowleft} /> {isMobile ? "" : "volver"}
            </span>
            <img
              src={logo}
              alt="corona logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.content}>
            {/*<button>*/}
            {/*  <h3 onClick={() => navigate("/sunsets/participate")}>*/}
            {/*    {" "}*/}
            {/*    REGISTRATE{" "}*/}
            {/*  </h3>*/}
            {/*</button>*/}
            <h4>
              {" "}
              Próximamente, los mejores Sunsets del mundo
            </h4>
          </div>
        </div>

        {/* <h1>
            {" "}
            CORONA SUNSET <br /> WORLD TOUR{" "}
          </h1> */}
      </div>
      <div className={styles.containerbg}>
        {videos.sort((v1, v2) => v1.index - v2.index).map(video => {
          return <div className={`${styles.videoContainer}`} key={video.id}>
            <ReactPlayer
                url={video.link}
                // title="Corona Sunset 2023"
                frameborder="0"
                // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                width="80%"
                height="80%"
                // playing
                loop
                controls
                // muted
                playbackRate={1}
                progressInterval={1000}
                onContextMenu={e => e.preventDefault()}
                config={{
                  file: {
                    attributes: {
                      playsInline: true,
                      controlsList: "nodownload",
                      disableRemotePlayback: true,
                      disablePictureInPicture: true,
                    },
                  },
                }}
                // onStart={() => {
                //     window.dataLayer.push({
                //         'event' : 'GAEvent',
                //         'event_category': 'Video',
                //         'event_action': `Play`,
                //         'event_label': `Momento magico`,
                //         'interaction': 'True',
                //     });
                // }}
                // onEnded={() => {
                //     window.dataLayer.push({
                //         'event' : 'GAEvent',
                //         'event_category': 'Video',
                //         'event_action': `Completed`,
                //         'event_label': `Corona Sunsets`,
                //         'interaction': 'True',
                //     });
                // }}
            />
          </div>
        })}
        <div className={`${styles.videoContainer}`}>
          <ReactPlayer
              url={'https://firebasestorage.googleapis.com/v0/b/casacorona.appspot.com/o/img_sunset2023%2Fvideo%20(1).mp4?alt=media'}
              title="Corona Sunset 2023"
              frameborder="0"
              // allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              width="100%"
              height="auto"
              playing
              loop
              // controls
              muted
              playbackRate={1}
              progressInterval={1000}
              onContextMenu={e => e.preventDefault()}
              config={{
                file: {
                  attributes: {
                    playsInline: true,
                    controlsList: "nodownload",
                    disableRemotePlayback: true,
                    disablePictureInPicture: true,
                  },
                },
              }}
              // onStart={() => {
              //     window.dataLayer.push({
              //         'event' : 'GAEvent',
              //         'event_category': 'Video',
              //         'event_action': `Play`,
              //         'event_label': `Momento magico`,
              //         'interaction': 'True',
              //     });
              // }}
              // onEnded={() => {
              //     window.dataLayer.push({
              //         'event' : 'GAEvent',
              //         'event_category': 'Video',
              //         'event_action': `Completed`,
              //         'event_label': `Corona Sunsets`,
              //         'interaction': 'True',
              //     });
              // }}
          />
        </div>
      </div>
    </>
  )
}

export default WorldTour


